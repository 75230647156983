import React from 'react'
import { getAnalyticsContext } from 'analytics'
import { openModal, preloadModal } from 'modals'
import { constants } from 'helpers'
import { checkUpsellModalCookie } from 'helpers/getters'
import { useAb, useFt } from 'hooks'
import { useShownPopups, useUser } from 'modules/user'
import { useCartAddProduct } from 'modules/cart'

import { buttonMessages } from 'components/inputs'

import useAddClickHandler from '../../util/useAddClickHandler'
import type { AddToButtonBaseProps } from '../AddToButtonBase/AddToButtonBase'
import AddToButtonBase from '../AddToButtonBase/AddToButtonBase'


export type AddToCartButtonProps = CartModule.AddProductInput &
CartModule.AddProductParams &
Pick<AddToButtonBaseProps, 'children' | 'className' | 'style' | 'size' | 'width' | 'fullWidth' | 'fullWidthOnMobile' | 'title' | 'onClick' | 'data-testid'>

const AddToCartButton: React.FunctionComponent<AddToCartButtonProps> = (props) => {
  const {
    children,
    className,
    style,
    size,
    width,
    fullWidth,
    fullWidthOnMobile,
    product,
    metadata,
    title = buttonMessages.addToCart,
    withErrorModals = true,
    withNotification = true,
    withRedirectToCheckout = false,
    onClick,
    'data-testid': dataTestId = 'addToCartButton',
  } = props

  const { shownPopups } = useShownPopups()
  const { user, isLoggedIn } = useUser()
  const [ submit, { isFetching } ] = useCartAddProduct()

  const isCaseUpsellModalEnabled = useFt(constants.features.caseUpsellAddToCart)
  const isHereticCaseUpsellModalEnabled = useFt(constants.features.caseUpsellHeretic)
  const isHidePopupsAbEnabled = useAb(constants.abTests.popupHidePopups) === 'b'

  const isLead = !isLoggedIn || user?.analyticsMetadata?.subscriptionStatus === 'LEAD'

  const { isAdded, handleClick } = useAddClickHandler(async () => {
    let openCaseUpsellModal: () => void
    let openDriftRefillClipDisclaimerModal: () => void

    const { page, pageType } = getAnalyticsContext()

    const processSubmit = async () => {
      const withCaseUpsellModal = Boolean(openCaseUpsellModal)
      const withCartOpen = props.withCartOpen !== undefined ? (
        props.withCartOpen
      ) : (
        !withRedirectToCheckout && !withCaseUpsellModal
      )

      await submit({
        product,
        metadata: {
          page,
          pageType,
          ...metadata,
        },
      }, {
        withErrorModals,
        withNotification: !withCaseUpsellModal && !withCartOpen && withNotification,
        withRedirectToCheckout,
        withCartOpen,
      })

      if (typeof onClick === 'function') {
        onClick()
      }
    }

    if (product.isRefill && !shownPopups['driftRefillClipDisclaimerModal']?.lastUpdated) {
      void preloadModal('driftRefillClipDisclaimerModal')

      openDriftRefillClipDisclaimerModal = () => {
        openModal('driftRefillClipDisclaimerModal', {
          productUid: product.productUid,
          tradingItemUid: product.tradingItemUid,
          onClose: processSubmit,
        })
      }
    }
    else if (product.isTravelSizeVial && !product.isLimitedDrop) {
      if (isHereticCaseUpsellModalEnabled && product.brand.toUpperCase().startsWith('HERETIC')) {
        const cookieCondition = checkUpsellModalCookie({
          cookieName: constants.cookieNames.isHereticCaseUpsellModalShown,
          countPerDay: 5,
          isSetCookieOnClose: !isHidePopupsAbEnabled,
        })

        const isVisible = (
          cookieCondition.isVisible
          && !isLead
        )

        if (isVisible) {
          void preloadModal('hereticCaseUpsellModal')

          openCaseUpsellModal = () => {
            openModal('hereticCaseUpsellModal', {
              product,
              addTo: 'cart',
              onClose: cookieCondition.onClose,
              onShow: cookieCondition.onShow,
            })
          }
        }
      }
      else if (isCaseUpsellModalEnabled) {
        const cookieCondition = checkUpsellModalCookie({
          cookieName: constants.cookieNames.isAddToCartCaseUpsellModalShown,
          countPerDay: 3,
          isSetCookieOnClose: !isHidePopupsAbEnabled,
        })

        const isVisible = (
          cookieCondition.isVisible
          && !isLead
        )

        if (isVisible) {
          void preloadModal('caseUpsellModal')

          openCaseUpsellModal = () => {
            openModal('caseUpsellModal', {
              product,
              addTo: 'cart',
              onClose: cookieCondition.onClose,
              onShow: cookieCondition.onShow,
            })
          }
        }
      }
    }

    if (openDriftRefillClipDisclaimerModal) {
      openDriftRefillClipDisclaimerModal()
    }
    else {
      await processSubmit()
    }

    if (openCaseUpsellModal) {
      openCaseUpsellModal()
    }
  })

  /* some products don't have tradingItem when they are out of stock, so we disable the button in this case */
  const isOutOfStock = !product.tradingItemUid

  return (
    <AddToButtonBase
      className={className}
      style={style}
      size={size}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      title={title}
      isAdded={isAdded}
      loading={isFetching}
      disabled={isOutOfStock}
      isOutOfStock={isOutOfStock}
      onClick={handleClick}
      data-testid={dataTestId}
      data-cnstrc-btn="add_to_cart"
    >
      {children}
    </AddToButtonBase>
  )
}


export default AddToCartButton
